import { useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FaInstagram, FaComment, FaHome, FaShoppingCart } from 'react-icons/fa';
import { sendForm } from '@emailjs/browser';

import h1 from './assets/lightchangestext.png';

// const ICON_SIZE = 24;
const ICON_SIZE_SMALL = 16;

function App() {
  const form = useRef();

  const handleFormSend = (e) => {
    e.preventDefault();
    sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        form.current.reset();
        alert("Form Sent, we will get back to you shortly", result.text);
      },
        (error) => {
          alert("An error occurred, please try again", error.text);
        });
  };

  return (
    <div className="parent">
      <header className="links">
        <a href="https://lemondroop.bigcartel.com/about" aria-label="Navigate to the Lemon Droop homepage"><FaHome color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://www.instagram.com/lemon.droop/?hl=en" aria-label="Instagram"><FaInstagram color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://lemondroop.bigcartel.com/products" aria-label="Shop"><FaShoppingCart color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://lemondroop.bigcartel.com/contact" aria-label="Contact"><FaComment color="#663770" size={ICON_SIZE_SMALL} /></a>
      </header>
      <main tabIndex={1}>
        {/* <br /> */}
        <h1>
          <img
            src={h1}
            alt="Light Changes"
            aria-label="Light Changes"
            className="h1Img"
          />
        </h1>
        <div className="bodyContainer">
          <br />
          <div className="h1Subheader">
            A PRINT EXCHANGE
          </div>
          <p>
            each print must contain at least one iridescent, opalescent, or transparent element
          </p>
          <Form className="form" ref={form} onSubmit={handleFormSend}>
            <Form.Group className="mb-3 formGroup" controlId="name">
              <Form.Label className="formLabel" >name:</Form.Label>
              <Form.Control className="textControl" type="text" name="name" />
            </Form.Group>
            <Form.Group className="mb-3 formGroup" controlId="pronouns">
              <Form.Label className="formLabel" >pronouns:</Form.Label>
              <Form.Control className="textControl" type="text" name="pronouns" />
            </Form.Group>
            <Form.Group className="mb-3 formGroup" controlId="email">
              <Form.Label className="formLabel" >email:</Form.Label>
              <Form.Control className="textControl" type="text" name="email" />
            </Form.Group>
            <Form.Group className="mb-3 formGroup" controlId="instagram">
              <Form.Label className="formLabel" >instagram:</Form.Label>
              <Form.Control className="textControl" type="text" name="instagram" />
            </Form.Group>
            <Form.Group className="mb-3 formGroup" controlId="website">
              <Form.Label className="formLabel" >website:</Form.Label>
              <Form.Control className="textControl" type="text" name="website" />
            </Form.Group>
            <p>
              if there is not a representative body of your work on the website or instagram listed above please email lemon.droop.shop@gmail.com 5-15 photos of your work after submitting this application
            </p>
            <Form.Group className="mb-3 formGroupTextArea" controlId="mediumChoice">
              <Form.Label className="formLabel" >what is your first choice of medium for the exchange?</Form.Label>
              <Form.Control className="textControl" as="textarea" name="mediumChoice" />
            </Form.Group>
            <Form.Group className="mb-3 formGroupTextArea" controlId="mediumBackups">
              <Form.Label className="formLabel" >are there any other print mediums you would consider if we have no more slots available in your first choice medium?</Form.Label>
              <Form.Control className="textControl" as="textarea" name="mediumBackups" />
            </Form.Group>
            <Form.Group className="mb-3 formGroupTextArea" controlId="ableToShip">
              <Form.Label className="formLabel" >will you be able to complete and ship an edition of 25 10” by 10” prints by July 30th? (if cost of shipping is a barrier, do not fret we can help with that cost)</Form.Label>
              <Form.Control className="textControl" as="textarea" name="ableToShip" />
            </Form.Group>
            <Form.Group className="mb-3 formGroupTextArea" controlId="containElement">
              <Form.Label className="formLabel" >will your prints contain at least one transparent, opalescent, or iridescent element?</Form.Label>
              <Form.Control className="textControl" as="textarea" name="containElement" />
            </Form.Group>
            <Form.Group className="mb-3 formGroupTextArea" controlId="critique">
              <Form.Label className="formLabel" >would you like to be connected to other artists in the exchange for critique, brainstorming, pen palship, etc? If yes, what would that look like to you in an ideal scenario? your answer to this question will not influence the jury process so whatever you preference we want to know it</Form.Label>
              <Form.Control className="textControl" as="textarea" name="critique" />
            </Form.Group>
            <p>
              applications will close June 30th, 2022 at midnight
            </p>
            <br />
            <p>
              if selected each artist will be asked to create an edition of 25 prints and send them to be packaged into 25 sets, each containing 1 print from each artist. 15 of those sets will be sent back to the artists involved, the other 10 will be put up for sale and profits will be split equally between all artists involved
            </p>
            <br />
            <p>
              the print created for this exchange must be exclusive to the exchange for 1 year post the day they go up for sale, then each artist will be free to reprint their own work as their hearts desire
            </p>
            <br />
            <Button className='submitButton' type="submit" size="lg" onClick={(e) => { }}>
              SUBMIT
            </Button>
            <br />
          </Form>
        </div>
      </main>
      <br />
      <footer className="links">
        <a href="https://lemondroop.bigcartel.com/about" aria-label="Navigate to the Lemon Droop homepage"><FaHome color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://www.instagram.com/lemon.droop/?hl=en" aria-label="Instagram"><FaInstagram color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://lemondroop.bigcartel.com/products" aria-label="Shop"><FaShoppingCart color="#663770" size={ICON_SIZE_SMALL} /></a>
        <a href="https://lemondroop.bigcartel.com/contact" aria-label="Contact"><FaComment color="#663770" size={ICON_SIZE_SMALL} /></a>
      </footer>
      <br />
    </div>
  );
}

export default App;
